



































import { seasonalImages, tawImages } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Auth extends Vue {
  autoColorSchemeEnabled = false;
  timeoutIds: Array<number> = [];

  get currentHomeImageSrc() {
    if (moment().isSameOrAfter(moment('2023-07-01', 'YYYY-MM-DD'))) {
      const current = moment();
      const tawDay = DateTimeUtils.getTawDay(current);

      if (tawDay !== -1) {
        return `https://cdn.planbook.com/${tawImages[tawDay]}`;
      }

      for (var i in this.seasonalImages) {
        const si = this.seasonalImages[i];
        const start = moment(`${si.start} 00:00:00`, 'MM-DD HH:mm:ss');
        const end = moment(`${si.end} 23:59:59`, 'MM-DD HH:mm:ss');

        if (current.isBetween(start, end, null, '[]')) {
          return `https://cdn.planbook.com/${si.src}`;
        }
      }
      return 'https://cdn.planbook.com/images/welcome-home-c.png';
    } else {
      return 'https://cdn.planbook.com/images/seasonal/welcome-home-beta-v12.png';
    }
  }

  get seasonalImages() {
    return seasonalImages;
  }

  autoChangeColorScheme() {
    if (!this.autoColorSchemeEnabled) {
      return;
    }
    const isDaylight = DateTimeUtils.isDaylight();
    this.$vuetify.theme.dark = !isDaylight;
    const current = moment();
    const midnight = moment('12:00 AM', 'HH:mm A');
    const tss = DateTimeUtils.getTzSunriseAndSunset();
    const mSunrise = moment(tss.sunrise, 'HH:mm A');
    const mSunset = moment(tss.sunset, 'HH:mm A');
    if (isDaylight) {
      mSunset.add(5, 'minutes');
      this.timeoutIds.push(setTimeout(() => {
        this.autoChangeColorScheme();
      }, mSunset.diff(current)));
    } else {
      if (current.isSameOrAfter(midnight)) {
        this.timeoutIds.push(setTimeout(() => {
          this.autoChangeColorScheme();
        }, mSunrise.diff(current)));
      } else {
        mSunrise.add(24, 'hours');
        this.timeoutIds.push(setTimeout(() => {
          this.autoChangeColorScheme();
        }, mSunrise.diff(current)));
      }
    }
  }

  beforeDestroy() {
    if (CommonUtils.isNotEmpty(this.timeoutIds)) {
      this.timeoutIds.forEach(tId => clearTimeout(tId));
    }
    this.autoColorSchemeEnabled = false;
    this.timeoutIds = [];
  }

  created() {
    this.autoColorSchemeEnabled = true;
    this.autoChangeColorScheme();
  }
}
