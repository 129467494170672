var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "welcome-container" },
      [
        _vm.$vuetify.breakpoint.mdAndUp
          ? _c(
              "v-row",
              { staticClass: "welcome ma-0 pa-0" },
              [
                _c(
                  "v-col",
                  { staticClass: "left", attrs: { cols: "8" } },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "welcome-text d-flex justify-center pa-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center white--text" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold welcome-header",
                                      },
                                      [_vm._v(_vm._s(_vm.$t("welcomeMsg")))]
                                    ),
                                    _c("div", {
                                      staticClass: "welcome-sub-header",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("welcomeMsg2")
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("img", {
                      staticClass: "welcome-image",
                      attrs: {
                        src: _vm.currentHomeImageSrc,
                        role: "presentation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "right my-auto", attrs: { cols: "4" } },
                  [_c("router-view")],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$vuetify.breakpoint.smAndDown
          ? _c(
              "v-row",
              {
                staticClass: "ma-0 pa-0",
                attrs: { align: "center", justify: "center" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    _c("v-img", {
                      staticClass: "ml-auto mr-auto",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        width: "200",
                        height: "auto",
                        src: "https://cdn.planbook.com/images/planbook-logo-blue.svg",
                        alt: _vm.$t("app.name"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$vuetify.breakpoint.smAndDown
          ? _c(
              "v-row",
              {
                staticClass: "welcome ma-0 pa-0",
                attrs: { align: "center", justify: "center" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "right sm text-center" },
                  [
                    _c("router-view"),
                    _c("v-spacer", { staticClass: "py-10 mb-10" }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }